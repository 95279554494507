<template>
	<div id="login">
		<v-form
			ref="form"
			v-model="form.valid"
			lazy-validation
		>
			<v-container>
				<v-row class="pt-10">
					<v-col sm="12" align="center">
						<h1>Forgot Password?</h1>
						<p>Please enter your account email below and click the reset password link.  You will receive an email with a reset password action.  <br><b>If you do not receive the email please check your junk or spam folder.</b></p>
					</v-col>
				</v-row>
				<v-row>
					<v-col sm="6" offset-sm="3">
						<v-row dense>
							<v-col>
								<v-text-field
									filled
									label="Email"
									v-model="form.fields.email"
									placeholder="name@domain.com"
									:rules="form.validation.emailRules"
									required
								></v-text-field>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col sm="12" align="right">
								<router-link class="ma-1 pr-3" x-large color="error" to="/login" plain>Back to Login</router-link> 
								<v-btn color="deep-orange darken-3" dark x-large @click.prevent="reset">
									Send Reset Link
								</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</v-form>
	</div>
</template>

<script>
	import ApiService from '@/services/ApiService.js';

	export default {
		name: "ForgotPassword.vue",
		data() {
			return {
				form: {
					valid: true,
					validation:{
						emailRules: [
							v => !!v || 'E-mail is required',
							v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
						],
					},
					fields:{
						email: "",
					}
				},
			};
		},
		computed: {},
		methods: {
			reset() {
				let self = this;
				if(this.$refs.form.validate()){
					ApiService
						.post("/account/forgot-password", this.form.fields)
						.then(() => {
							self.$root.snackbar.message = "Please check your email!";
							self.$root.snackbar.open = true;
							self.$router.replace('/')
						})
						.catch((err) => {
							self.$root.snackbar.message = err.message;
							self.$root.snackbar.open = true;
						})
						// .finally(() => (this.loading = false));
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	h1 {
		line-height: 1.2em;
	}
	textarea {
		height: 13rem;
	}
	.map {
		border-radius: 10px;
		overflow: hidden;
		border: solid 1px #cfd8dc;
	}
</style>
